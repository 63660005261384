import { useContext, useState } from 'react';
import {
  alpha,
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListSubheader,
  styled
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import {
  ExpandLess,
  ExpandMore,
  DashboardTwoTone,
  ShoppingCartTwoTone,
  AssignmentReturnTwoTone,
  LocalShippingTwoTone,
  AttachMoneyTwoTone,
  MoneyOffTwoTone,
  HowToRegTwoTone,
  RequestQuoteTwoTone,
  AccountBalanceWalletTwoTone,
  AssessmentTwoTone,
  DateRangeTwoTone,
  ScheduleTwoTone,
  InsertInvitationTwoTone,
  PendingActionsTwoTone,
  TrendingUpTwoTone,
  TrendingDownTwoTone,
  FactCheckTwoTone,
  PaymentTwoTone,
  ApartmentTwoTone,
  PeopleAltTwoTone,
  AccountBalanceTwoTone,
  LocalMallTwoTone,
  CategoryTwoTone,
  InventoryTwoTone,
  BusinessCenterTwoTone,
  DesignServicesTwoTone,
  GroupTwoTone,
  PublicTwoTone,
  MapTwoTone,
  GavelTwoTone,
  MonetizationOnTwoTone,
  StraightenTwoTone,
  StoreTwoTone,
  CorporateFareTwoTone,
  FlagTwoTone
} from '@mui/icons-material';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};
  }
  .MuiListSubheader-root {
    text-transform: uppercase;
    font-weight: bold;
    font-size: ${theme.typography.pxToRem(12)};
    color: ${theme.colors.alpha.trueWhite[50]};
    padding: ${theme.spacing(0, 0.5)};
    line-height: 1;
  }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      .MuiListItem-root {
        padding: 1px 0;
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};
          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};
          }
        }
      }
    }
`
);

const getIconByName = (name) => {
  switch (name) {
    case 'Dashboards':
      return <DashboardTwoTone />;
    case 'Purchase Entry':
      return <ShoppingCartTwoTone />;
    case 'Issue Entry':
      return <AssignmentReturnTwoTone />;
    case 'Booking Entry':
      return <InsertInvitationTwoTone />;
    case 'Packing Entry':
      return <LocalShippingTwoTone />;
    case 'Income Entry':
      return <AttachMoneyTwoTone />;
    case 'Expenses Entry':
      return <MoneyOffTwoTone />;
    case 'Attendance Entry':
      return <HowToRegTwoTone />;
    case 'Payroll Entry':
      return <RequestQuoteTwoTone />;
    case 'Wages Entry':
      return <AccountBalanceWalletTwoTone />;
    case 'Stock Ledger':
      return <AssessmentTwoTone />;
    case 'Datewise Purchases':
      return <DateRangeTwoTone />;
    case 'Datewise Issues':
      return <ScheduleTwoTone />;
    case 'Datewise Booking':
      return <InsertInvitationTwoTone />;
    case 'Issue Pending':
      return <PendingActionsTwoTone />;
    case 'Packing Pending':
      return <LocalShippingTwoTone />;
    case 'Incomes':
      return <TrendingUpTwoTone />;
    case 'Expenses':
      return <TrendingDownTwoTone />;
    case 'Attendance':
      return <FactCheckTwoTone />;
    case 'Payroll':
      return <PaymentTwoTone />;
    case 'Wages':
      return <AccountBalanceWalletTwoTone />;
    case 'Department Master':
      return <ApartmentTwoTone />;
    case 'Employee Master':
      return <PeopleAltTwoTone />;
    case 'Accounts Head':
      return <AccountBalanceTwoTone />;
    case 'Supplier Master':
      return <LocalMallTwoTone />;
    case 'Product Group Master':
      return <CategoryTwoTone />;
    case 'Product Master':
      return <InventoryTwoTone />;
    case 'Garment Type Master':
      return <BusinessCenterTwoTone />;
    case 'Pattern Master':
      return <DesignServicesTwoTone />;
    case 'Consumables Master':
      return <CategoryTwoTone />;
    case 'Customer Master':
      return <GroupTwoTone />;
    case 'Company':
      return <CorporateFareTwoTone />;
    case 'Users':
      return <GroupTwoTone />;
    case 'Country':
      return <PublicTwoTone />;
    case 'State':
      return <MapTwoTone />;
    case 'Settlement Mode':
      return <GavelTwoTone />;
    case 'Tax Group':
      return <MonetizationOnTwoTone />;
    case 'Taxes':
      return <FlagTwoTone />;
    case 'UOM':
      return <StraightenTwoTone />;
    default:
      return <MonetizationOnTwoTone />;
  }
};

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const [openMenus, setOpenMenus] = useState({
    entry: false,
    master: false,
    reports: false,
    admin: false
  });

  const handleToggle = (menu) => {
    setOpenMenus((prev) => ({
      ...prev,
      [menu]: !prev[menu]
    }));
  };

  return (
    <MenuWrapper>
      <List component="div">
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/"
                startIcon={getIconByName('Dashboards')}
              >
                Dashboards
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>

      {[
        {
          name: 'Entry',
          menuKey: 'entry',
          items: [
            'Purchase Entry',
            'Booking Entry',
            'Issue Entry',
            'Cutting Entry',
            'Stitching Entry',
            'Packing Entry',
            'Sales Entry',
            'Income Entry',
            'Expenses Entry',
            'Attendance Entry',
            'Payroll Entry',
            'Wages Entry'
          ]
        },
        {
          name: 'Reports',
          menuKey: 'reports',
          items: [
            'Stock',
            'Stock Ledger',
            'Datewise Purchases',
            'Datewise Issues',
            'Datewise Booking',
            'Datewise Cutting',
            'Datewise Stitching',
            'Datewise Packing',
            'Issue Pending',
            'Cutting Pending',
            'Stitching Pending',
            'Packing Pending',
            'Datewise Sales',
            'Incomes',
            'Expenses',
            'Attendance',
            'Payroll',
            'Wages'
          ]
        },
        {
          name: 'Master',
          menuKey: 'master',
          items: [
            'Department Master',
            'Employee Master',
            'Accounts Head',
            'Supplier Master',
            'Product Group Master',
            'Product Master',
            'Garment Type Master',
            'Pattern Master',
            'Consumables Master',
            'Customer Master'
          ]
        },
        {
          name: 'Admin',
          menuKey: 'admin',
          items: [
            'Company',
            'Users',
            'Country',
            'State',
            'Settlement Mode',
            'Tax Group',
            'Taxes',
            'UOM'
          ]
        }
      ].map(({ name, menuKey, items }) => (
        <List
          component="div"
          subheader={
            <Button
              onClick={() => handleToggle(menuKey)}
              endIcon={openMenus[menuKey] ? <ExpandLess /> : <ExpandMore />}
            >
              <ListSubheader component="div" disableSticky>
                {name}
              </ListSubheader>
            </Button>
          }
          key={menuKey}
        >
          <Collapse in={openMenus[menuKey]} timeout="auto" unmountOnExit>
            <SubMenuWrapper>
              <List component="div">
                {items.map((item) => (
                  <ListItem component="div" key={item}>
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to={`/${menuKey.toLowerCase()}/${item
                        .replace(/\s+/g, '')
                        .toLowerCase()}`}
                      startIcon={getIconByName(item)}
                    >
                      {item}
                    </Button>
                  </ListItem>
                ))}
              </List>
            </SubMenuWrapper>
          </Collapse>
        </List>
      ))}
    </MenuWrapper>
  );
}

export default SidebarMenu;
